.profile-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 90vw;
    padding: 1rem;
}

.profile-container button {
    margin: 10px;
    border: 1px solid black;
    border-radius: 2px;
    width: 250px;
    padding: 0.5rem;
}

.profile-container button:hover {
    background-color: lightgrey;
}

.user-profile {
    display: flex;
    justify-content: center;
    border: 1px solid black;
    border-radius: 2px;
    width: 90vw;
}



.beats-container {
    display: flex;
    flex-direction: column;
    width: 90vw;
    border: 1px solid black;
    border-radius: 2px;
    padding-left: 1rem;
}

.uploaded-beats {
    padding: 1rem;
    
}

.uploaded-beats li {
    display: flex;
    flex-direction: column;
    align-items: center;
    
}


.uploaded-beats button {
    margin: 10px;
    border: 1px solid black;
    border-radius: 2px;
    width: 250px;
}

.uploaded-beats button:hover {
    background-color: lightgrey;
}


/* .user-profile {
    display: flex;
    justify-content: center;
    width: 100vw;
    padding: 2rem;
}






 */
