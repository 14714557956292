.sign-up-container {
    display: flex;
    justify-content: center;
    
    width: 100vw;
    align-items: center;
    
}

.sign-up-container form {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 4rem;
    border: 1px solid black;
    border-radius: 2%;
}

.sign-up-container button {
    border-radius: 3px;
    border: 1px solid black;
    padding: 10px;
    width: 5rem;
    background-color: white;
    color: black;
    font-size: 1.1em;
    margin-top: 15px;
}

.sign-up-container input {
    border-radius: 3px;
    border: 1px solid black;
    margin: 1rem;
    padding: 10px;
    width: 10rem;
    height: 2rem;
}