
.navigation {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding-bottom: 2rem;
    padding-top: 2rem;
}

.nav-links {
    display: flex;
    text-decoration: none;
    color: black;
    font-weight: 300;
    font-size: 20px;
}

button {
    text-decoration: none;
    background-color: transparent;
    border: 0px solid;
    font-size: 25px;
    font-family: 'Roboto', sans-serif;
}

/* .nav-links  button{
    display: flex;
    text-decoration: none;
    color: black;
    font-weight: 300;
    font-size: 25px;
    
} */