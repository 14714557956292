.cart {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .cart-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 80%;
    margin: 1rem 0;
    padding: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    
  }
  
  .cart-item h3 {
    margin: 0;
  }
  
  .cart-item button {
    background-color: #f44336;
    color: black;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    cursor: pointer;
    margin: 0 auto;
    font-size: medium;
    position: fixed;
    left: 50%;
    right: 50%;
    margin-left: -50px; /* half of the width of the element */
    margin-right: -50px; 
  }
  
  .cart-item button:hover {
    background-color: #e57373;
  }


  .total-price {
    display: flex;
    justify-content: flex-end;
    flex-direction: row-reverse;
  }
  .total-price button {
    border-radius: 3px;
    border: 1px solid black;
    padding: 10px;
    width: 250px;
    background-color: white;
    color: black;
    font-size: 1.1em;
    margin-top: 15px;
    cursor: pointer;
  }