.dropbox-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid black;
    background-color: white;
    color: #0060FF;
    border-radius: 3px;
    width: 250px;
    height: 5vw;
    padding: 1rem;
}

.dropbox-logo {
    /* display: flex;
    flex-direction: row; */
    width: 2vw;
}

.button-container {
    display: flex;
    justify-content: center;
    width: 100vw;
    align-items: center;
    padding-top: 5rem;
}

.beats-info-boxes {
    display: flex;
    justify-content: center;
    flex-direction: row;
    
}

.beat-box {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    border: 1px solid black;
    width: 20vw;
    margin: 2rem;
}

.beat-box button {
    border: 1px solid black;
    border-radius: 2px;
    
}

input {
    border-radius: 3px;
    border: 1px solid black;
    margin: 1rem;
    padding: 10px;
    width: 500px;
    height: 2rem;
}

.create-beat-button {
    border-radius: 3px;
    border: 1px solid black;
    padding: 10px;
    width: 250px;
    background-color: white;
    color: black;
    font-size: 1.1em;
    margin-top: 15px;
}

.dropbox-button {
    border-radius: 3px;
    border: 1px solid black;
    padding: 10px;
    width: 250px;
    background-color: white;
    color: black;
    font-size: 1.1em;
    margin-top: 15px;
}