@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  max-height: 100vh;
  max-width: 100vw;
}






body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #cbd5e1;
  /* background: rgb(172,172,172); */
  /* background: radial-gradient(circle, rgba(172,172,172,1) 0%, rgba(111,111,111,1) 100%);*/
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.main-head-container {
  color: black;
}

Link {
  cursor: pointer;
}

button {
  cursor: pointer;
}

button:hover {
  background-color: lightgrey;
}